import React from "react"
import SEO from "../components/seo"
import translationHelper from "../i18n/helper"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import SectionTitleSeperator from "../components/Misc/SectionTitleSeperator/SectionTitleSeperator"
import Layout from "../components/Layout/layout"
import ContactForm from "../components/Contact/ContactForm/contactForm"

const askForOffer = (props) => {
  console.debug(props)
  const {locale} = props.pageContext
  return (

    <Layout locale={props.pageContext.locale} path={props.path}>
    <SEO title={translationHelper[locale]["askForOfferTitle"]} lang={props.pageContext.locale} description={translationHelper[locale]["askForOfferDescription"]}/>
    <Container>
      <Row>
        <Col md={12}>
          <EmptySpaceSeperator pixels={40}/>
          <SectionTitleSeperator hideTop={true} title={translationHelper[locale]["askForOffer"]}/>
        </Col>
      </Row>
    </Container>
      <Container fluid={true} className="p-0">
        <Row noGutters={true}>
          <Col md={12}>
            <ContactForm formId="askOffer" />
            <h2 className="h2TitleStyled text-center mainColor p-4">{translationHelper[locale]["weDeliverTheProjectYouNeed"]} </h2>
          </Col>
        </Row>
      </Container>
  </Layout>
  )
}

export default askForOffer

